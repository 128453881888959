<!--
 * @Author: your name
 * @Date: 2021-07-27 16:03:10
 * @LastEditTime: 2021-10-24 09:10:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/views/About.vue
-->
<template>
  <div class="about">
    <!--
    <h3>This is an authorize page</h3>
    <h6>wechat-url:{{ url }}</h6>
    <h6>code:{{ code }}</h6>
    <h6>urls:{{ urls }}</h6>
    <div>
      <h6>openid:{{}}</h6>
    </div>
    <div>
      <h6>school-code:{{ scode }}</h6>
    </div>
    <div>
      <vue-qr ref="Qrcode" :text="urlsCode" qid="testQrId"></vue-qr>
    </div>
    -->
    <h3>正在跳转..</h3>
  </div>
</template>
<script>
//import VueQr from "vue-qr";

export default {
  //components: { VueQr },
  data() {
    return {
      url: "",
      urls: "",
      op: "",
      code: "",
      scode: "",
      urlsCode: "",
    };
  },
  created() {
    this.urlsCode = window.location.href;
    let redirect_uri = encodeURIComponent(
      document.location.protocol +
        "//" +
        window.location.host +
        "/weChat/oauth2-login?OP=1008&u=" +
        this.$route.query.u
    );
    this.url =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf99fad9253c6594d&redirect_uri=" +
      redirect_uri +
      "&response_type=code&scope=snsapi_base&state#wechat_redirect";
  },
  mounted() {
    if (this.$sopen.isWechat() == true) {
      this.authorize();
    }
  },
  methods: {
    authorize() {
      window.location.href = this.url;
    },
    uscode() {
      var data = {
        module: "wechat",
        title: "uscode",
        data: {
          appid: this.op,
          code: this.code,
        },
      };
      let that = this;
      this.$sopen.requestApi(data).then((res) => {
        that.scode = res;
      });
    },
  },
};
</script>